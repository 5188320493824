import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "selectedIcon", "selectedInput" ]

  workspaceSelected(event) {
    this.selectedInputTarget.value = event.params.workspaceId

    this.selectedIconTarget.classList.forEach(klass => {
      // Ignore the font awesome CSS class for making icons fixed width
      if(klass == 'fa-fw') return

      // Remove the font awesome icon class that was previously selected
      if(klass.startsWith('fa-')) this.selectedIconTarget.classList.remove(klass)
    });

    // Add the font awesome icon class that was just selected
    this.selectedIconTarget.classList.add(`fa-${event.params.workspaceIcon}`)

    let mainInputField = $(this.selectedInputTarget).parents('.input-group').children('input[autofocus="autofocus"]')
    $(mainInputField).focus()
  }
}
