import ApplicationController from './application_controller'
import "ninja-keys"
import hotkeys from "hotkeys-js"
import _ from "lodash"

import extractDataFromDataOrElement from './command_palette/extract_data_from_data_or_element'
import sortCommands from './command_palette/sort_commands'

export default class extends ApplicationController {
  static targets = [
    "command",
    "palette"
  ]

  initialize() {
    hotkeys.filter = _event => true // allow hotkeys even when <input>, <select>, or <textarea> have focus
    this.commands = []
  }

  commandTargetConnected(target) {
    // Ensure that new command elements are added to the command palette
    this._addCommand(target)
  }

  commandTargetDisconnected(target) {
    // Remove command elements that disappear
    this._removeCommand(target)
  }

  paletteTargetConnected(target) {
    // Enforce that page transitions do not lose state
    this._setPaletteCommands()
  }

  open() {
    this.paletteTarget.open()
  }

  // private functions
  _addCommand(dataOrElement) {
    const data = extractDataFromDataOrElement(dataOrElement)
    this._updateCommands([ ...this.commands, data ])
  }

  _removeCommand(element) {
    const data = extractDataFromDataOrElement(element)
    this._updateCommands(this.commands.filter(command => command.id == data.id))
  }

  _updateCommands(keys) {
    this.commands = sortCommands(keys)

    this._setPaletteCommands()
  }

  _setPaletteCommands() {
    this.paletteTarget.data = this.commands
  }
}
