// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import Lightbox from 'stimulus-lightbox'
import Sortable from 'stimulus-sortable'
import PasswordVisibility from 'stimulus-password-visibility'

import CableReady from 'cable_ready'
import { processElements } from "cable_ready/javascript/utils"

CableReady.operations.clearForm = operation => {
  operation.selector = operation.selector || "form"
  processElements(operation, element => {
    console.log("clearing form", operation, element)
    element.reset && element.reset()
  })
}

import consumer from '../channels/consumer'

consumer.subscriptions.create({ channel: "WorkspaceChannel" }, {
  received(data) {
    console.log('received', data)
    if (data.cableReady) {
      CableReady.perform(data.operations)
    }
  }
})

const context = require.context("controllers", true, /_controller\.js$/)
const contextComponents = require.context("../../components", true, /_controller\.js$/)

const application = Application.start()

application.register('lightbox', Lightbox)
application.register('sortable', Sortable)
application.register('password-visibility', PasswordVisibility)

application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents)
  )
)

application.consumer = consumer

