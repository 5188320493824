// ----- Datepicker -----
$(document).on('turbo:load', () => {
  $('input.js-datepicker').each((idx, el) => {
    let input = $(el)
    input.datetimepicker({
      timepicker:false,
      format: 'Y-m-d',
      minDate: input.data('min-date'),
      allowBlank: true
    });
  })
})


// ----- Select 2 -----
$(document).on('turbo:load', () => {
  $('select.js-select2').each((idx, el) => {
    let select = $(el)
    let selectConfig = {
      placeholder: select.data('placeholder'),
      allowClear: true,
    };

    select.select2(selectConfig);

    // Related with https://github.com/select2/select2/issues/3320
    let onUnselecting = () => select.data('unselecting', true)
    let onOpen = () => {
      if (select.data('unselecting')) {
        select.select2('close').removeData('unselecting')
      }
    }

    select
      .on('select2:unselecting',onUnselecting)
      .on('select2:open', onOpen)

  })
})

// ----- js-only-one -----
$(document).on('turbo:load', () => {
  $(document.body).on('change', '.js-only-one', (event) => {
    $('.js-only-one').each((idx, field) => {
      console.log(event.target, field)
      if (event.target == field) return

      $(field).val("")
    })
  })
})

//--- sidebar-dropdown ---
$(document).on('turbo:load', () => {
  $(document.body).on('click', ".sidebar-dropdown > a", (event) => {
    event.preventDefault()
    console.log('sidebar-dropdown click')

    $(".sidebar-submenu").slideUp(200)

    let target = $(event.target)

    if(target.parent().hasClass("active")) {
      $(".sidebar-dropdown").removeClass("active")
      target.parent().removeClass("active")

    } else {
      $(".sidebar-dropdown").removeClass("active");
      target.next(".sidebar-submenu").slideDown(200);
      target.parent().addClass("active");
    }
  })
})


// --- sidebar
$(document).on('turbo:load', () => {
  $(document.body).on('click', '.js-close-sidebar', () => {
    $(".page-wrapper").removeClass("toggled")
  })

  $(document.body).on('click', '.js-show-sidebar', () => {
    $(".page-wrapper").addClass("toggled");
    event.preventDefault();
  })
})


// --- page-wrapper ---
$(document).on('turbo:load', () => {
  $(document.body).on('click touchstart', (event) => {
    if ($(event.target).closest('.sidebar-wrapper').length > 0) return
    if ($(event.target).closest('.js-show-sidebar').length > 0) return

    $(".page-wrapper").removeClass("toggled")
  })

  $(document).on('turbo:visit',        () => $(".page-wrapper").removeClass("toggled"))
  $(document).on('turbo:before-cache', () => $(".page-wrapper").removeClass("toggled"))
})



// --- background color + tooltip ---
$(document).on('turbo:load turbo:frame-load', (event) => {
  $('[data-toggle="tooltip"]').tooltip()

  $("[data-background-color]").map((idx, el) => {
    let element = $(el)

    let background_color = element.data('background-color')
    let foreground_color = element.data('foreground-color')

    console.log(background_color, foreground_color)

    if (!foreground_color) {
      let bg_hex = background_color.replace("#", "")
      let r = parseInt(bg_hex.substr(0,2),16)
      let g = parseInt(bg_hex.substr(2,2),16)
      let b = parseInt(bg_hex.substr(4,2),16)
      let yiq = ((r*299)+(g*587)+(b*114))/1000
      let foreground_color = yiq >= 128 ? '#343a40' : '#f8f9fa'
    }

    element.css('background-color', background_color)
    element.css('color', foreground_color)
  })
})
