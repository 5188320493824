import ApplicationController from '../frontend/controllers/application_controller'

export default class extends ApplicationController {
  static values = {
    workspaceIds: Array
  }

  workspaceReload(event) {
    if (!this.workspaceIdsValue.includes(event.detail.workspaceId)) return
    this.triggerReload()
  }

  triggerReload() {
    let frames = document.querySelectorAll('turbo-frame[src]')

    if (frames && frames.length > 0) {
      console.log('reloading all frames')
      frames.forEach((element) => {
        if(element.dataset.reload == 'false') return

        console.log(element)
        element.reload()
      })

    } else {
      console.log('reload full page')
      Turbo.visit(window.location.href, { action: "replace" })
    }
  }
}
