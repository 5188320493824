import ApplicationController from '../../frontend/controllers/application_controller'

export default class extends ApplicationController {
  static targets = ["modal", "modalContent"]

  // hide modal
  closeModal() {
    this.element.parentElement.removeAttribute("src")
    // Remove src reference from parent frame element
    // Without this, turbo won't re-open the modal on subsequent click
    this.modalTarget.remove()
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.closeModal()
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    if (e && this.modalContentTarget.contains(e.target)) {
      return
    }
    this.closeModal()
  }
}
