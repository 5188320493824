/* eslint no-console:0 */

import { Turbo } from "@hotwired/turbo-rails"
import mrujs from "mrujs"
import CableReady from 'cable_ready'
import { CableCar } from "mrujs/plugins"
import "trix"
import "@rails/actiontext"

import Dropzone from "dropzone";
window.Dropzone = Dropzone

window.Turbo = Turbo
Turbo.setFormMode("optin")

mrujs.start({
  plugins: [
    new CableCar(CableReady)
  ]
})

import 'javascripts/_copy_button'
import 'javascripts/_hack'

import "controllers"

import 'lightgallery/css/lightgallery.css'
