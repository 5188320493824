export default (commands) => {
  var remainingCommands = commands

  var [favoriteCommands, remainingCommands] = _.partition(remainingCommands, (command) => command.section == 'Favorites')
  var [createCommands, remainingCommands] = _.partition(remainingCommands, (command) => command.section == 'Create...')
  var [boxesCommands, remainingCommands] = _.partition(remainingCommands, (command) => command.section == 'Boxes')
  var [accountCommands, remainingCommands] = _.partition(remainingCommands, (command) => command.section == 'Account')
  var [calendarCommands, remainingCommands] = _.partition(remainingCommands, (command) => command.section == 'Calendar')

  const orderedCommands = [
    ...favoriteCommands,
    ...boxesCommands,
    ...createCommands,
    ...remainingCommands,
    ...calendarCommands,
    ...accountCommands
  ]

  const uniqueCommands = _.uniqBy(orderedCommands, (command) => command.id)
  return uniqueCommands
}
