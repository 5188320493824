export default (dataOrElement) => {
  switch (dataOrElement.constructor.name) {
    case "HTMLMetaElement":
      return extractFromMetaElement(dataOrElement)

    case "HTMLAnchorElement":
    case "HTMLDivElement":
    case "HTMLSpanElement":
      return extractFromElement(dataOrElement)

    case "Object":
      return extractFromObject(dataOrElement)
  }

  return {}
}

// Private Extractors ---
const extractFromMetaElement = (metaElement) => {
  const dataset = metaElement.dataset

  const {id, title, section, hotkey, keywords, link, turboFrame} = dataset
  const icon = buildIconElement(dataset.icon)
  const handler = buildLinkHandler(dataset)

  return {
    id,
    title,
    hotkey,
    icon,
    section,
    keywords,
    handler
  }
}

const extractFromElement = (element) => {
  const { dataset } = element

  const id = dataset.commandPaletteId || dataset.commandPaletteText || element.innerText
  const title = dataset.commandPaletteText || element.innerText
  const icon = buildIconElement(dataset.commandPaletteIcon) || extractIcon(element)
  const keywords = [dataset.commandPaletteKeywords, dataset.commandPaletteSection, title].filter(e => !!e).join(" ")

  return {
    id,
    title,
    hotkey: dataset.commandPaletteHotkey,
    icon,
    section: dataset.commandPaletteSection,
    keywords,
    handler: () => element.click()
  }
}

const extractFromObject = (data) => {
  return {
    ...data,
    icon: buildIconElement(data.icon)
  }
}


// Private Helpers ---
const buildLinkHandler = ({link, turboFrame}) => {
  if(!link) return null

  return () => {
    var options = {}

    if(turboFrame) options.frame = turboFrame

    Turbo.visit(link, options)
  }
}

const buildIconElement = (icon) => {
  if (!icon) { return }

  return `<i class='fa fa-${icon}'></i>`
}

const extractIcon = (element) => {
  const existingIcon = element.querySelector("i")
  if (!existingIcon) { return }

  return existingIcon.outerHTML
}
