$(document).on('turbo:load', () => {
  let clipboard = new ClipboardJS('.js-copy-btn')
  clipboard.on('success', (e) => {
    console.info('Action:', e.action)
    console.info('Text:', e.text)
    console.info('Trigger:', e.trigger)

    $(e.trigger).tooltip({ placement: 'bottom', title: 'Copied!' })

    e.clearSelection()
  })

  $(document).one('turbo:visit', () => {
    clipboard.destroy()
  })
})
